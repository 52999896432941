#prepaid-dialog {
  display: block;
}
.trans-banner-active #prepaid-dialog,
.gtrans-active #prepaid-dialog {
  padding-top: 40px;
}

.prepaid-dialog-content {
  background: transparent;
  width: 340px;
  position: relative;
  text-align: center;
  line-height: 1.2;

  padding-top: 30px;
  margin-top: 52px;
}
.prepaid-img {
  position: absolute;
  left: -123px;
  top: 95px;
  z-index: -1;
}
.prepaid-header {
  font-weight: 800;
  font-size: 16px;
  margin: 0;
  color: #fff;
}
.prepaid-stars-icon {
  margin: 0 3px 5px;
  height: 13px;
}
.prepaid-header2 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 800;
  color: #30053f;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.prepaid-content-div {
  border-radius: 10px;
  background: #fff;
  width: 100%;
}
.prepaid-close-icon {
  position: absolute;
  color: #fff;
  font-size: 24px;
  right: 0;
  top: -8px;
  opacity: 1;
  font-weight: 400;
  box-shadow: none;
}
.prepaid-info-p {
  clear: both;
  font-size: 14px;
  color: rgba(7, 36, 22, 0.5);
  padding: 0 45px;
}
.prepaid-div2 {
  background: #f2f2f2;
  margin-top: 30px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 10px;
}
.prepaid-lock-icon {
  margin: 24px 0 20px;
}
.prepaid-input-p {
  font-size: 13px;
  padding: 0 50px;
}
.prepaid-first-input {
  width: 240px;
  height: 47px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  box-sizing: border-box;
  display: block;
  margin: 20px auto 5px;
  width: 75%;
  text-align: center;
  color: #072416;
  font-size: 16px;
}
.failed-input {
  border-color: #ee0e0e;
}
.success-input {
  border-color: #259fa5;
}
.succes-tick {
  position: absolute;
  margin-top: -48px;
  right: 40px;
}
.prepaid-verify-btn {
  background: #259fa5;
  border-radius: 3px;
  padding: 15px 0px;
  width: 160px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  margin: 35px auto 15px;
  border: none;
  display: block;
}
.prepaid-cancel-p {
  cursor: pointer;
  display: block;
}
.prepaid-failed-msg {
  color: #ee0e0e;
  font-size: 13px;
  margin-bottom: -23px;
  line-height: 18px;
  padding: 0 30px;
}
.prepaid-success-msg {
  color: #259fa5;
  font-size: 13px;
  margin-bottom: -23px;
  line-height: 18px;
  padding: 0 30px;
}
.prepaid-contact-btn {
  border: none;
  background: transparent;
  color: #259fa5;
  font-size: 13px;
  font-weight: 800;
  text-decoration-line: underline;
  display: block;
  margin: 5px auto;
  margin-bottom: 20px;
}
.prepaid-steps-div {
  display: flex;
  flex-direction: row;
  width: 83px;
  margin: 0px auto;
  padding: 35px 0 25px;
}
.prepaid-inactive-step,
.prepaid-active-step {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #259fa5;
  display: inline-block;
}
.prepaid-active-step {
  background: #cfcfcf;
}
.prepaid-inactive-step-bar,
.prepaid-active-step-bar {
  width: 45px;
  margin: 8px auto;
  border: 1px solid #259fa5;
}
.prepaid-active-step-bar {
  border-color: #cfcfcf;
}
.prepaid-step-p {
  color: #fff;
  font-size: 11px;
  margin: 0px 2px;
  font-weight: 800;
  line-height: 19px;
}

@media screen and (max-width: 768px) {
  .prepaid-dialog-content {
    margin: 20% auto 5%;
  }
}

@media screen and (max-width: 599px) {
  .prepaid-dialog-content {
    width: 78%;
    margin: 20% auto 5%;
  }
  .prepaid-img {
    position: absolute;
    right: -90px;
    left: unset;
    top: -75px;
    z-index: -1;
    transform: scale(-1, 1);
  }
  .prepaid-cancel-p {
    text-align: center;
  }
  .prepaid-info-p {
    padding: 0 24px;
  }
}
.succes-tick {
  @media screen and (max-width: 600px) {
    right: 45px;
  }

  @media screen and (max-width: 450px) {
    right: 35px;
  }

  @media screen and (max-width: 400px) {
    right: 30px;
  }
}
